import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banquePro/banquePro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const go = '/go/?go=Shine&url=https://www.shine.fr/lp/ref/bankbank'
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 4.4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 4.5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Shine : à lire absolument avant d'ouvrir un compte pro</title>
                <meta name="description" content="
              Shine est une banque pro pour freelancer et entreprises. Elle offre 2 formules basics et premium ainsi qu'un service comptable. Avis Shine de BankBank." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'black'}}>Banque Pro</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Shine</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Shine</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Shine est une néobanque pro destinée aux entreprises. Elle offre un service complet notamment pour les petites structures. Découvrez notre avis Shine :</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Offre Shine <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/shine-logo.png'
			width={250}
			alt='logo shine'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



		<h2>Compte Pro Shine</h2>
		<p>De 3.90€ TTC / mois à 79€ HT/ mois, l’offre et les tarifs de Shine dépendent du type d’entreprise que vous avez ou souhaitez créer :</p>
		<ul>
			<li>compte pro microentreprise et freelancers</li>
			<li>compte pro sociétés (EURL, SARL, SA, SAS, SASU)</li>
			<li>service comptabilités</li>
		</ul>

<iframe id='videoYT'src="https://www.youtube.com/embed/KOuQnqTyr5Y" title="avis Shine" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>

<h2>L’offre banque pro de Shine</h2>

<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/shine-site.png'
			width={800}
			alt='site shine'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>

<h3>Banque pro pour Micro entreprise, auto entrepreneur, freelance</h3>
<ul>
	<li>Création : Statut + compte pro gratuit pendant 6 mois : 34.90€ TTC puis 3.90€ TTC / mois</li>
	<li>Compte pro : 3.90€ TTC / mois</li>
	<li>Compte pro premium : 9.90€ TTC / mois</li>
</ul>

<h3>Banque pro pour Sociétés</h3>
<ul>
	<li>Création : dépôt de capital + 12 mois d’abonnement inclus puis 7.90€ HT / mois</li>
	<li>Compte pro basic : 7.90€ HT / mois</li>
	<li>Compte pro premium : 14.90€ HT / mois</li>
</ul>


<div style={{marginLeft: -5}}>
<DataTable row={[33,34,35,36]} id='postTable'/>
<DataTable row={[33,34,35,36]} id='mobileTable'/>

</div>

<p>Peu importe le type de structure choisi, les différences entre les comptes basic et premium sont les mêmes :</p>

<h3>Carte Shine Basic</h3>
<ul>
	<li>IBAN français</li>
	<li>Mastercard Business prépayée</li>
	<li>Support 7 jours sur 7</li>
	<li>20 virements et prélèvements / mois</li>
</ul>

<h3>Carte Shine Premium</h3>
<ul>
	<li>IBAN français</li>
	<li>Mastercard Business World Debit</li>
	<li>Support prioritaire 7 jours sur 7</li>
	<li>50 virements et prélèvements / mois</li>
	<li>Encaissement de chèques (2 gratuits / mois)</li>
	<li>Garantie accident : 100 € / jour en cas d’hospitalisation</li>
	<li>Assurance mobile : réparation d’écran couverte jusqu’à 150 €</li>
	<li>Protection matériel : doublement de la garantie constructeur</li>
	<li>Assistance juridique : accès à des juristes experts par téléphone</li>
</ul>

<h3>Offre comptable Shine : 79 € HT / mois</h3>
<p>
Shine offre aussi une aide à la rédaction des statuts par un expert comptable partenaire + accompagnement comptable, à partir de 79 € HT / mois. Ce comptable sera parfaitement connecté à votre compte Shine et pourra gérer les tâches administratives suivantes :
</p>
<ul>
	<li>votre saisie comptable</li>
	<li>déclarations fiscales TVA et impôts</li>
	<li>assemblée générale de fin d’année</li>
	<li>bilan / liasse fiscale</li>
	<li>conseil social, fiscal & juridique illimité (téléphone, mail, RDV au cabinet)</li>
</ul>



			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Services Shine <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>


	<h2>Avis Shine – les services qui vont faciliter la vie de votre entreprise</h2>

<h3>Fonction blocage</h3>
<ul>
	<li>Plafond de paiement</li>
	<li>Paiement sans contact</li>
	<li>Bloquer complètement la carte</li>
</ul>

<h3>Comptabilité</h3>
<ul>
	<li>Envoi automatique des documents à votre comptable</li>
	<li>Micro-entrepreneur : estimation des charges et rappels des échéances administratives</li>
</ul>

<h3>Fonctions de paiements</h3>
<ul>
	<li>Création de factures et paiement en ligne</li>
	<li>Accord privilégié avec le terminal de paiement Sumup : 1.49% par transaction au lieu de 1.75% et le terminal à 19€ au lieu de 39€.</li>
</ul>

<h3>Encaissement de chèques</h3>
<p>
Le dépôt de chèques est gratuits chez Shine pour le compte Premium à hauteur de 2 chèques par mois. Une fois cette limite dépassée chaque dépôt de chèque vous coûtera 2€.
</p><p>
A noter que l’encaissement de chèques n’est pas possible pour le compte basic.
</p>



<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>



	<h2>Avis Shine</h2>
	<p>
Shine dispose actuellement de 50 000 clients actifs. Notre avis shine – elle fait la différence sur les assurances ainsi que ses fonctions d’aide juridique. Si l’offre est ouverte aux entreprises de toutes les tailles, Shine, c’est avant tout une banque pour les freelancers et les créations de petite structure. On le voit notamment avec le simulateur dédié à déterminer le revenu du freelancing vs le salariat.
</p><p>
Avis Apple Store : 4.9/5<br></br>
Avis Google Play : 4.8/5
</p>

<h3>Avantages du compte pro Shine</h3>
<ul>
	<li>Assurance incluse</li>
	<li>Aide juridique et rappels d’échéances administratives</li>
	<li>Outils de création de facture avec paiement en ligne</li>
	<li>Offre création très compétitive</li>
</ul>


<h3>Inconvénients du compte pro Shine</h3>
<ul>
	<li>L’offre devient assez limitée et couteuse pour les grosses structures.</li>
</ul>


<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
